import { Button, Col, Layout, List, Row, Spin, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import commitmentService from "../../services/commitmentService";
import searchHelper from "../../utils/helpers/searchHelper";
import { getUid } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { CreateLevel } from "../teacher/createLevel";

const { Text, Title } = Typography;

interface renderGroupInterface {
  group: any;
}

export function RenderGroup(props: renderGroupInterface) {
  const getColor = (type: string): string => {
    switch (type) {
      case "cycle":
        return "cyan";
      case "faculty":
        return "cyan";
      case "section":
        return "orange";
      case "department":
        return "orange";
      case "option":
        return "lime";
      case "option_class":
        return "lime";
      default:
        return "blue";
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: "2px 0" }}>
        <Tag color={getColor(props.group.type)}>{props.group?.name}</Tag>
      </div>
      {!isEmpty(props.group?.parent) && (
        <RenderGroup group={props.group?.parent} />
      )}
    </React.Fragment>
  );
}

export function LevelResponsible() {
  const levels = useSelector((state: RootState) => state.teacher.levels);
  const user = useSelector((state: RootState) => state.user.profil);
  const [loadings, setLoadings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const onInit = React.useCallback(async () => {
    setLoading(levels.length === 0);
    await searchHelper.getTeacherLevels(user, dispatch);
    setLoading(false);
  }, [user]);

  React.useEffect(() => {
    onInit();
  }, [onInit]);

  async function handleRemove(item: any) {
    if (!userHelper.isTeacher(user)) {
      return;
    }
    let items = [...loadings];
    if (items.findIndex((p) => p === item["@id"]) === -1) {
      items.push(item["@id"]);
      setLoadings(items);
      const teacher = userHelper.getTeacher(user);
      const data = { teacher: teacher.uid, level: getUid(item?.level["@id"]) };
      console.log("fd", data);
      await commitmentService
        .store(data)
        .then(async () => {
          await searchHelper.getTeacherLevels(user, dispatch);
        })
        .catch((reason) => {
          console.warn("RESPONSE", reason.response);
        });
      items = [...loadings];
      items = items.filter((p) => p !== item["@id"]);
      setLoadings(items);
    }
  }

  const renderItem = (item: any) => {
    const loading = loadings.findIndex((p) => p === item["@id"]) !== -1;
    return (
      <List.Item style={{ background: "#fff" }}>
        <Row
          justify="center"
          align="middle"
          style={{ width: "100%", padding: "0 10px" }}
          gutter={16}
          wrap={false}
        >
          <Col flex={1}>
            <Row align="middle" gutter={4}>
              <Col md={10} xs={24}>
                <Text strong>{`Niveau: `}</Text>
                <Text>{item?.level?.title}</Text>
              </Col>
              <Col md={6} xs={24}>
                <Row gutter={8} align="middle">
                  <Col>
                    <Text strong>{`Groupe: `}</Text>
                  </Col>
                  <Col>
                    <RenderGroup group={item?.level?.groups} />
                  </Col>
                </Row>
              </Col>
              <Col md={4} xs={24}>
                <Text strong>{`Nombre de cours: `}</Text>
                <Text style={{ textAlign: "center" }}>
                  {item?.level?.subjects?.length ?? "0"}
                </Text>
              </Col>
            </Row>
          </Col>
          {
            <Col style={{ display: "none" }}>
              <Button
                danger
                loading={loading}
                disabled={loading}
                onClick={() => handleRemove(item)}
              >
                Supprimer
              </Button>
            </Col>
          }
        </Row>
      </List.Item>
    );
  };
  return (
    <Spin
      spinning={loading}
      tip={loading ? `Attendez pendant le chargement des classes` : ""}
    >
      <Layout style={{ background: "#fff" }}>
        <Row align="middle" style={{ padding: "10px" }}>
          <Col flex={1}>
            <Title level={2} ellipsis={{ rows: 1 }}>
              {`Les classes de prestation`}
            </Title>
          </Col>
          <Col>
            <CreateLevel user={user} title="Ajouter des classes" />
          </Col>
        </Row>
        <List dataSource={levels} renderItem={renderItem} />
      </Layout>
    </Spin>
  );
}
