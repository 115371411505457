import { Layout } from "antd";
import React from "react";

export function FooterHome() {
  return (
    <Layout.Footer style={{ background: "#fff" }}>
      <div style={{ textAlign: "center" }}>
        {`Tout droit reservé`}{" "}
        <a href="#" title="AI-EDTECH Sarl" target="_target">
          {`AI-EDTECH Sarl`}
        </a>{" "}
        {`©${new Date().getFullYear()}`}
      </div>
    </Layout.Footer>
  );
}
