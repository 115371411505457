import { Button, Collapse, Layout, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
//import rules from "../rules";
import { setAppTitle } from "../utils/helpers/title";
import dateHelpers from "../utils/helpers/dateHelpers";
import yecData from "../utils/pricacies.json";
import { isEmpty, keys } from "lodash";
//import { isEmpty } from "lodash";

const { Paragraph, Text, Title } = Typography;

export function PrivacyScreen() {
  setAppTitle("Termes et conditions");

  /*const renderItem = (item: any, index: number) => {
    const list:any [] = [];
    const content = item.content;
    if(typeof content === 'string' ){
      list.push({key:'',value:content})
    }else{
      for (const key in content) {
        if (Object.prototype.hasOwnProperty.call(content, key)) {
          const value = content[key];
          list.push({key,value});          
        }
      }
    }
    return (
      <article key={`article_${index}`}>
        <Collapse.Panel header={(
          <Title level={2}>{`${item.title}`}</Title>
        )} key={`article_section_${index}`}>

        </Collapse.Panel>
        
        {list.map((p,i)=>(
          <div key={`element_${index}_${i}`}>
            {!isEmpty(p.key) && (<Text strong>{p.key}</Text>)}
            <Paragraph style={{ textAlign: "justify" }}>{p.value}</Paragraph>
          </div>
        ))}
      </article>
    );
  };*/

  return (
    <Layout
      className="yc-box"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        padding: 10,
        overflowX: "hidden",
        overflowY: "visible",
      }}
    >
      <Title style={{ textAlign: "center" }}>{`${yecData.title}`}</Title>
      <Text style={{ textAlign: "center", marginTop: -25, marginBottom: 20 }}>
        {`En vigueur au  ${dateHelpers.getDate("2024-11-01", "DD MMMM YYYY")}`}
      </Text>
      <article>
        <Paragraph style={{ textAlign: "justify" }}>
          {yecData.acceptance}
        </Paragraph>
      </article>
      {/*<List
        dataSource={yecData.sections}
        renderItem={renderItem}
      />*/}
      <Collapse
        style={{
          background: "#FFF",
          margin: 0,
          border: "none",
          marginLeft: -10,
        }}
        defaultActiveKey={keys(yecData.sections)}
      >
        {yecData.sections.map((item, index) => {
          const list: any[] = [];
          const content: any = item.content;
          if (typeof content === "string") {
            list.push({ key: "", value: content });
          } else {
            for (const key in content) {
              if (Object.prototype.hasOwnProperty.call(content, key)) {
                const value = content[key];
                list.push({ key, value });
              }
            }
          }
          return (
            <Collapse.Panel
              header={
                <Title
                  style={{ fontSize: 20 }}
                  level={2}
                >{`${item.title}`}</Title>
              }
              key={`${index}`}
            >
              {list.map((p, i) => (
                <div key={`element_${index}_${i}`}>
                  {!isEmpty(p.key) && <Text strong>{p.key}</Text>}
                  <Paragraph style={{ textAlign: "justify" }}>
                    {p.value}
                  </Paragraph>
                </div>
              ))}
            </Collapse.Panel>
          );
        })}
      </Collapse>
      <map style={{ position: "fixed", top: 60, right: 30 }}>
        <Button type="primary">
          <Link to="/home/register">{"S'inscrire"}</Link>
        </Button>
      </map>
    </Layout>
  );
}
